import toast from 'react-hot-toast';

export default function useToast() {
  function catchErrorToast(errObj: any) {
    if (errObj?.response?.data) {
      toast.error(errObj.response.data);
    }
  }

  function successToast(msg: string) {
    toast.success(msg);
  }

  function errorToast(msg: string) {
    toast.error(msg);
  }

  function toastSuccess(message: string) {
    toast.success(message);
  }

  function toastError(message: string) {
    toast.error(message);
  }

  function hideToast() {
    toast.dismiss();
  }

  return {
    catchErrorToast,
    successToast,
    errorToast,
    toastSuccess,
    hideToast,
    toastError,
  };
}
